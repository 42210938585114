import React from 'react';

// === Components === //
import Helmet from 'react-helmet';
import ButtonLink from '@components/common/ButtonLink/ButtonLink';
import ImageWebp from '@components/common/ImageWebp/ImageWebp';

// === Helpers === //
import classnames from 'classnames';
import { videoObject } from '@helpers/schemaOrg';
import * as utils from '@utils';

// === Styles === //
import * as styles from '@components/sections/Hero/Hero.module.scss';

// === Types === //
import { IHero } from '@components/sections/Hero/Hero.d';

const Hero = ({
  variant,
  title,
  description,
  image,
  buttons,
  backgroundVariant,
  backgroundVideo,
  schemaData,
}: IHero) => {
  const variantCapitalize = utils.capitalizeString(variant);
  const backgroundVariantCapitalize = utils.capitalizeString(backgroundVariant);

  return (
    <>
      {schemaData && (
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(
              videoObject(
                backgroundVideo,
                '',
                schemaData.name,
                schemaData.description,
                schemaData.uploadDate,
                schemaData.duration
              )
            )}
          </script>
        </Helmet>
      )}
      <div
        className={classnames(
          styles['heroWrapper'],
          styles[`hero${variantCapitalize}Wrapper`],
          (!backgroundVideo || backgroundVideo === '') &&
            styles[`heroWrapperBackground${utils.capitalizeString(backgroundVariant)}`]
        )}
        style={
          backgroundVariant === 'product'
            ? { backgroundImage: `url(${image?.desktop.webp || image?.desktop.url})` }
            : {}
        }
      >
        <div
          className={classnames(
            styles['heroWrapperBackgroundBottom'],
            styles[`hero${variantCapitalize}WrapperBackgroundBottom`],
            (variant === 'row' || variant === 'column') &&
              image &&
              (!backgroundVideo || backgroundVideo === '') &&
              styles[`heroWrapperBackgroundBottom${utils.capitalizeString(backgroundVariant)}`]
          )}
        >
          {backgroundVideo && backgroundVideo !== '' && (
            <video
              id="heroBackgroundVideo"
              autoPlay
              loop
              muted
              playsInline
              className={classnames(styles['heroVideo'])}
            >
              <source src={backgroundVideo} type="video/mp4" />
            </video>
          )}

          <div
            className={classnames(
              variant === 'row' || variant === 'rowSmall' ? 'page-wrapper' : '',
              styles['heroInner'],
              styles[`hero${variantCapitalize}Inner`],
              styles[`hero${variantCapitalize}Inner${backgroundVariantCapitalize}`]
            )}
          >
            <div className={classnames(variant === 'column' ? 'page-wrapper' : '')}>
              <div className={classnames(styles[`hero${variantCapitalize}Content`])}>
                <h1>{title}</h1>
                <div
                  className={classnames(
                    styles[`hero${variantCapitalize}Description`],
                    styles['heroDescription']
                  )}
                >
                  {utils.safeHtml(description)}
                </div>
                {buttons && buttons.length > 0 && (
                  <div
                    className={classnames(
                      styles['heroButtons'],
                      styles[`hero${variantCapitalize}Buttons`]
                    )}
                  >
                    {buttons.map((btn) => (
                      <ButtonLink
                        key={btn.label}
                        variant={btn.variant}
                        label={btn.label}
                        href={btn.href}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div
              className={classnames(
                backgroundVariant === 'product' && styles['heroImageProduct'],
                styles[`hero${variantCapitalize}ImageWrapper`],
                styles[`hero${variantCapitalize}ImageWrapper${backgroundVariantCapitalize}`]
              )}
            >
              {image && variant !== 'rowSmall' && (
                <>
                  {backgroundVariant !== 'product' && image && image.desktop.url !== '' && (
                    <ImageWebp
                      image={image.desktop.url}
                      webp={image.desktop.webp}
                      alt={image.desktop.alt}
                      title={image.desktop.title}
                      className={classnames('hide-on-mobile')}
                      width={variant === 'column' ? '1430px' : '570px'}
                      height={variant === 'column' ? '305px' : '806px'}
                    />
                  )}
                  {image && (image.mobile.url !== '' || image.desktop.url !== '') && (
                    <ImageWebp
                      image={image.mobile ? image.mobile.url : image.desktop.url}
                      webp={image.mobile ? image.mobile.webp : image.desktop.webp}
                      alt={image.mobile ? image.mobile.alt : image.desktop.alt}
                      title={image.mobile ? image.mobile.title : image.desktop.title}
                      className={classnames('hide-on-desktop')}
                      width={variant === 'column' ? '375px' : '767px'}
                      height={variant === 'column' ? '272px' : '806px'}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
