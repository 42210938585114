// extracted by mini-css-extract-plugin
export var accordionTitle = "ContactLiveDemoRegister-module--accordion-title--JEF1v";
export var accordionTitleIcon = "ContactLiveDemoRegister-module--accordion-title-icon--rryJP";
export var accordionTitleIconActive = "ContactLiveDemoRegister-module--accordion-title-icon-active--Z+-Cv";
export var cldrDescription = "ContactLiveDemoRegister-module--cldr-description--ydJTj";
export var cldrHeadline = "ContactLiveDemoRegister-module--cldr-headline--Hq4bt";
export var cldrHeadlineWrapper = "ContactLiveDemoRegister-module--cldr-headline-wrapper--2jWno";
export var cldrLocationInfo = "ContactLiveDemoRegister-module--cldr-location-info--UfGV0";
export var cldrLocationInfoHeadline = "ContactLiveDemoRegister-module--cldr-location-info-headline--vzoe2";
export var cldrLocationInfoInner = "ContactLiveDemoRegister-module--cldr-location-info-inner--gADIU";
export var cldrLocationInfoWrapper = "ContactLiveDemoRegister-module--cldr-location-info-wrapper--RVipu";
export var cldrPanel = "ContactLiveDemoRegister-module--cldr-panel--ntIbx";
export var cldrWrappepr = "ContactLiveDemoRegister-module--cldr-wrappepr--789hP";
export var cldrYouCanBookMe = "ContactLiveDemoRegister-module--cldr-you-can-book-me--cE9em";
export var cldrYouCanBookMeHeadline = "ContactLiveDemoRegister-module--cldr-you-can-book-me-headline--hpI4g";
export var hideOnDesktop = "ContactLiveDemoRegister-module--hide-on-desktop--jdOt3";
export var hideOnMobile = "ContactLiveDemoRegister-module--hide-on-mobile--3F1I7";
export var slideBody = "ContactLiveDemoRegister-module--slide-body---XfEx";
export var slideImage = "ContactLiveDemoRegister-module--slide-image--4eelb";
export var slideLanguages = "ContactLiveDemoRegister-module--slide-languages--bCPfS";
export var slideLanguagesCopy = "ContactLiveDemoRegister-module--slide-languages-copy--BSq2u";
export var slideLanguagesHeadline = "ContactLiveDemoRegister-module--slide-languages-headline--Z3P+7";
export var slideProduct = "ContactLiveDemoRegister-module--slide-product--w1-wm";
export var slideProductImage = "ContactLiveDemoRegister-module--slide-product-image--BW8Og";
export var slideProductTitle = "ContactLiveDemoRegister-module--slide-product-title--ksLgH";
export var slideProducts = "ContactLiveDemoRegister-module--slide-products--IyRkz";