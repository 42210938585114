import React from 'react';

// === Components === //
import Helmet from 'react-helmet';
import Accordion from '@components/common/Accordion/Accordion';
import Headline from '@components/common/Headline/Headline';

// === Helpers === //
import cn from 'classnames';
import { faqObject } from '@helpers/schemaOrg';

// === Styles === //
import * as styles from '@components/sections/Faq/Faq.module.scss';

const FaqSection = ({ items, headline, background }: FaqSectionData) => {
  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(faqObject(items))}</script>
      </Helmet>
      <div className={cn(styles['FaqSectionWrapper'], background && 'gray-bg')}>
        <Headline title={headline} />
        <div className={'page-wrapper'}>
          <Accordion className={styles['FaqAccordion']} items={items} />
        </div>
      </div>
    </>
  );
};

export default FaqSection;
