import React, { useState } from 'react';

import { IAccordion } from './Accordion.d';
// === Helpers === //
import cn from 'classnames';
import * as utils from '@utils';
import { Collapse } from 'react-collapse';
import arrow from '@assets/svg/accordion-arrow.svg';

// === Styles === //
import * as styles from '@components/common/Accordion/Accordion.module.scss';

const Accordion = ({ items, className = '' }: IAccordion) => {
  const freshState = items.map(() => {
    return false;
  });
  const [isOpened, setisOpened] = useState<boolean[]>(freshState);

  function toggleCollapse(index) {
    const arrayClone = [...freshState];
    arrayClone[index] = !isOpened[index];
    setisOpened(arrayClone);
  }

  return (
    <div className={cn(className)}>
      {items.map((item, index) => {
        return (
          <div
            key={item.title + index}
            className={cn(
              styles['accordionItem'],
              styles[isOpened[index] ? 'accordionItemOpen' : '']
            )}
          >
            <div className={styles['accordionTitle']} onClick={() => toggleCollapse(index)}>
              <span className={styles['accordionTitleInner']}>{item.title}</span>
              <button className={styles['accordionButton']} aria-label="accordion-icon">
                <img
                  className={styles['accordionArrow']}
                  src={arrow}
                  alt=""
                  width="16px"
                  height="9px"
                  loading="lazy"
                />
              </button>
            </div>
            <Collapse isOpened={isOpened[index]}>
              <div
                className={cn(
                  styles['accordionContentWrap'],
                  'strip-top-bottom-margins-from-p-wrap'
                )}
              >
                <div className={cn(styles['accordionContent'], 'strip-copy-margin-wrap')}>
                  {utils.safeHtml(item.content)}
                </div>
              </div>
            </Collapse>
          </div>
        );
      })}
    </div>
  );
};

export default Accordion;
