import { IHeroVariantFromAPI } from '@components/sections/Hero/Hero.d';

export const heroVariant: IHeroVariantFromAPI = {
  main_banner_variant_1: {
    variant: 'column',
    bgVariant: 'homepage',
  },
  main_banner_variant_2: {
    variant: 'row',
    bgVariant: 'default',
  },
  main_banner_variant_3: {
    variant: 'rowSmall',
    bgVariant: 'default',
  },
  main_banner_variant_4: {
    variant: 'row',
    bgVariant: 'product',
  },
  main_banner_variant_5: {
    variant: 'row',
    bgVariant: 'contact',
  },
};