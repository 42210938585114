import React, { useState } from 'react';

// === Assets === //
import chevronDown from '@assets/svg/chevron-down-blue.svg';

// === Components === //
import Helmet from 'react-helmet';
import { Collapse } from 'react-collapse';
import { Link } from 'gatsby';
import { Slider } from '@components/common/Slider/Slider';
import ImageWebp from '@components/common/ImageWebp/ImageWebp';

// === Helpers === //
import cn from 'classnames';
import * as utils from '@utils';

// === Styles === //
import * as styles from '@components/sections/Contact/ContactLiveDemoRegister/ContactLiveDemoRegister.module.scss';

// === Types === //
import {
  IContactLiveDemoRegister,
  IContactLiveDemoRegisterGalleryCarousel,
  ILocationInfo,
} from '@components/sections/Contact/ContactLiveDemoRegister/ContactLiveDemoRegister.d';

const LocationInfo = ({ gallery }: ILocationInfo) => {
  return (
    <div className={cn(styles['cldrLocationInfo'])}>
      <h4 className={cn('safe-html-inherit-fonts', styles['cldrLocationInfoHeadline'])}>
        {utils.safeHtml(gallery.headline)}
      </h4>
      <Slider
        variant="youCanBookMe"
        breakpoints={{
          280: {
            slidesPerView: 1,
            centeredSlides: false,
          },
        }}
        arrows={true}
      >
        {gallery.carousel.map((item: IContactLiveDemoRegisterGalleryCarousel, index: number) => (
          <div key={index}>
            {item.image && (
              <div className={cn(styles['slideImage'])}>
                <ImageWebp
                  webp={item.image.webp}
                  image={item.image.url}
                  alt={item.image.alt}
                  title={item.image.title}
                  width="555px"
                  height="322px"
                />
              </div>
            )}
            <div className={cn(styles['slideBody'])}>
              <h4 className={cn('safe-html-inherit-fonts', styles['cldrLocationInfoHeadline'])}>
                {utils.safeHtml(item.subheadline)}
              </h4>
              {item.products && item.products.length > 0 && (
                <ul className={cn(styles['slideProducts'])}>
                  {item.products.map((product, pIndex) => (
                    <li key={pIndex} className={cn(styles['slideProduct'])}>
                      <Link to={product.url}>
                        {product.image && (
                          <div className={cn(styles['slideProductImage'])}>
                            <ImageWebp
                              webp={product.image.webp}
                              image={product.image.url}
                              alt={product.image.alt}
                              title={product.image.title}
                              width="69px"
                              height="69px"
                            />
                          </div>
                        )}
                        <p className={cn(styles['slideProductTitle'])}>{product.title}</p>
                      </Link>
                    </li>
                  ))}
                </ul>
              )}

              <div className={cn(styles['slideLanguages'])}>
                <h4
                  className={cn(
                    'safe-html-inherit-fonts',
                    styles['cldrLocationInfoHeadline'],
                    styles['slideLanguagesHeadline']
                  )}
                >
                  {utils.safeHtml(item.preferedLanguagesHeadline)}
                </h4>
                <p className={cn(styles['slideLanguagesCopy'])}>{item.preferedLanguages}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

const ContactLiveDemoRegister = ({
  copy,
  gallery,
  headline,
  ycbm,
  galleryAccordionTitle,
}: IContactLiveDemoRegister) => {
  const [accordionOpen, setAccordionOpen] = useState<boolean>(false);

  return (
    <>
      {ycbm && ycbm.script !== '' && utils.scriptFromString(ycbm.script) && (
        <Helmet>{utils.scriptFromString(ycbm.script)}</Helmet>
      )}
      <div className={cn(styles['cldrWrappepr'])}>
        <div className={cn('page-wrapper')}>
          <div className={cn(styles['cldrHeadlineWrapper'])}>
            <h2 className={cn('safe-html-inherit-fonts', styles['cldrHeadline'])}>
              {utils.safeHtml(headline)}
            </h2>
            <div className={cn('safe-html-inherit-fonts', styles['cldrDescription'])}>
              {utils.safeHtml(copy)}
            </div>
          </div>

          {ycbm && ycbm.headline !== '' && (
            <h4
              className={cn(
                'safe-html-inherit-fonts',
                styles['hideOnDesktop'],
                styles['cldrLocationInfoHeadline'],
                styles['cldrYouCanBookMeHeadline']
              )}
            >
              {utils.safeHtml(ycbm.headline)}
            </h4>
          )}

          <div className={cn(styles['cldrPanel'])}>
            <div className={cn(styles['cldrLocationInfoWrapper'])}>
              <div className={cn(styles['cldrLocationInfoInner'])}>
                <div className={cn(styles['hideOnDesktop'])}>
                  <div>
                    <button
                      className={cn(styles['accordionTitle'])}
                      onClick={() => setAccordionOpen(!accordionOpen)}
                      aria-label="accordion-title-icon"
                    >
                      {galleryAccordionTitle}
                      <img
                        src={chevronDown}
                        alt=""
                        className={cn(
                          styles['accordionTitleIcon'],
                          accordionOpen && styles['accordionTitleIconActive']
                        )}
                        width="11px"
                        height="6px"
                        loading="lazy"
                      />
                    </button>
                  </div>
                  <Collapse isOpened={accordionOpen}>
                    <LocationInfo gallery={gallery} />
                  </Collapse>
                </div>
                <div className={cn(styles['hideOnMobile'])}>
                  <LocationInfo gallery={gallery} />
                </div>
              </div>
            </div>
            {ycbm && (
              <div className={cn(styles['cldrYouCanBookMe'])}>
                <h4
                  className={cn(
                    'safe-html-inherit-fonts',
                    styles['hideOnMobile'],
                    styles['cldrLocationInfoHeadline'],
                    styles['cldrYouCanBookMeHeadline']
                  )}
                >
                  {utils.safeHtml(ycbm.headline)}
                </h4>
                <div>{utils.safeHtml(ycbm.script)}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactLiveDemoRegister;
